define("discourse/plugins/discourse-interstitials/discourse/routes/admin-plugins-discourse-interstitial-edit", ["exports", "rsvp", "discourse/plugins/discourse-interstitials/discourse/routes/interstitial-form-route"], function (_exports, _rsvp, _interstitialFormRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsDiscourseInterstitialEdit extends _interstitialFormRoute.default {
    controllerName = "admin-plugins-discourse-interstitial-edit";
    model(params) {
      return (0, _rsvp.hash)({
        interstitial: this.store.find("discourse-interstitial-interstitial", params.id)
      });
    }
  }
  _exports.default = AdminPluginsDiscourseInterstitialEdit;
});