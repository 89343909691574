define("discourse/plugins/discourse-interstitials/discourse/routes/interstitial-form-route", ["exports", "@ember/object", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _object, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InterstitialFormRoute extends _discourse.default {
    _dateField(opts) {
      return _object.default.create({
        name: opts.name,
        type: "date-time",
        component: "date_time",
        metadata: {
          value: opts.value
        },
        instructions: opts.instructions,
        isRequired: !!opts.isRequired,
        isDisabled: !!opts.isDisabled,
        label: "discourse_interstitial.models.interstitial." + opts.name + ".label"
      });
    }
    _groupsField(opts) {
      return _object.default.create({
        onChangeField: opts.onChangeField,
        name: "group_ids",
        type: "group",
        component: "group",
        maximum: opts.maximum || 5,
        metadata: {
          value: opts.value
        }
      });
    }
    _userField(opts) {
      return _object.default.create({
        onChangeField: opts.onChangeField,
        name: "users",
        type: "user",
        component: "user",
        maximum: opts.maximum || 5,
        metadata: {
          value: opts.value
        }
      });
    }
    _categoryField(opts) {
      return _object.default.create({
        onChangeField: opts.onChangeField,
        name: "category_ids",
        type: "category",
        component: "category",
        maximum: opts.maximum || 5,
        metadata: {
          value: opts.value
        }
      });
    }
    setupController(controller, model) {
      const interstitial = model.interstitial;
      let ctaButtons = [].concat(interstitial.cta_buttons).filter(Boolean);
      let ctaButtonsQuantity = this.siteSettings.show_snooze_cta_button ? 3 : 2;
      if (ctaButtons.length < ctaButtonsQuantity) {
        for (let i = ctaButtons.length; i < ctaButtonsQuantity; i++) {
          ctaButtons.push({
            title: "",
            content: "",
            color: "",
            id: ""
          });
        }
      }
      controller.setProperties({
        model,
        interstitialForm: _object.default.create({
          id: interstitial.id,
          mobile_only: interstitial.mobile_only,
          title: interstitial.title,
          heading: interstitial.heading,
          content: interstitial.content,
          enabled: interstitial.enabled,
          exit_intent_trigger: interstitial.exit_intent_trigger,
          time_spent_trigger: interstitial.time_spent_trigger,
          cta_buttons_alignment: interstitial.cta_buttons_alignment || "right",
          cta_buttons_alignments: [{
            id: "left",
            name: "Left"
          }, {
            id: "middle",
            name: "Middle"
          }, {
            id: "right",
            name: "Right"
          }],
          cta_buttons: ctaButtons,
          interstitial_style: interstitial.interstitial_style || {
            width: "",
            height: "",
            background_color: ""
          },
          fields: [this._dateField({
            name: "start_at",
            value: interstitial.start_at
          }), this._dateField({
            name: "end_at",
            value: interstitial.end_at,
            instructions: (0, _discourseI18n.i18n)("discourse_interstitial.form.dates_instructions")
          }), this._groupsField({
            value: interstitial.groups,
            onChangeField: (field, value) => {
              interstitial.set("groups", value);
              field.set("metadata", {
                ...field.metadata,
                value: interstitial.groups
              });
            }
          }), this._userField({
            value: interstitial.users,
            onChangeField: (field, value) => {
              interstitial.set("users", value);
              field.set("metadata", {
                ...field.metadata,
                value: interstitial.users
              });
            }
          }), this._categoryField({
            value: interstitial.categories,
            onChangeField: (field, value) => {
              interstitial.set("categories", value);
              field.set("metadata", {
                ...field.metadata,
                value: interstitial.categories
              });
            }
          })],
          interstitial_types: [{
            id: "content",
            name: "Content",
            description: "Used for content interstitials"
          }, {
            id: "badge",
            name: "Badge",
            description: "Used for badges interstitials"
          }],
          interstitial_type: interstitial.interstitial_type,
          priorities: [{
            id: "low",
            name: "Low"
          }, {
            id: "normal",
            name: "Normal"
          }, {
            id: "high",
            name: "High"
          }],
          priority: interstitial.priority,
          start_at: interstitial.start_at,
          end_at: interstitial.end_at,
          members: interstitial.members,
          anonymous_users: interstitial.anonymous_users,
          recurring_anonymous_users: interstitial.recurring_anonymous_users,
          image_url: interstitial.image_url,
          image_upload_id: interstitial.image_upload_id
        })
      });
    }
    refreshRoute() {
      return this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "refreshRoute", [_object.action]))();
  }
  _exports.default = InterstitialFormRoute;
});